import { breakpoints } from './breakpoints'
import { color } from './color'
import { fontSize, lineHeight } from './text'
import { unit, space } from './space'
import { shadow } from './shadow'

export const theme = {
  breakpoints,
  color,
  fontSize,
  unit,
  lineHeight,
  shadow,
  space,
}
