import React, { createContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getH2HeadersContentFromHtml, createAnchorIdFromHeader } from '../helpers/html-helpers'

export const DOCS_TYPE = {
  USER_GUIDE: 'User Guide',
  API_REFERENCE: 'API Reference',
}

export const DocsContext = createContext({})

export const DocsProvider = ({ children }) => {
  const [docs, setDocs] = useState([])
  const [activeAnchorPath, setActiveAnchorPath] = useState()

  const {
    allContentfulDocumentation: { nodes },
  } = useStaticQuery(
    graphql`
      query AllDocs {
        allContentfulDocumentation {
          nodes {
            id
            type
            slug
            order
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    if (nodes && nodes.length && !docs.length) {
      setDocs(
        nodes
          .sort((a, b) => a.order - b.order)
          .map(n => {
            const html = n.body.childMarkdownRemark.html
            const headers = getH2HeadersContentFromHtml(html)
            const anchorIds = headers.map(h => createAnchorIdFromHeader(h))
            return {
              id: n.id,
              type: n.type,
              slug: n.slug,
              order: n.order,
              title: n.title,
              path: `/docs/${n.type.toLowerCase().replace(/\s/, '-')}/${n.slug}`,
              headers,
              anchorIds,
            }
          })
      )
    }
  }, [nodes, docs, setDocs])

  const value = {
    docs,
    activeAnchorPath,
    setActiveAnchorPath,
  }

  return <DocsContext.Provider value={value}>{children}</DocsContext.Provider>
}
