import { css } from '@emotion/core'
import { theme } from './theme'

const codeBlockColor = {
  background: theme.color.neutral.darker,
  main: theme.color.neutral.lighter,
  comment: theme.color.neutral.dark,
  number: '#f78c6c',
  string: '#c3e88d',
  keyword: '#c792ea',
  function: '#82aaff',
  class: '#ffcb6b',
  operator: '#89ddff',
  punctuation: '#89ddff',
}

export default css`
  code[class*='language-'],
  pre[class*='language-'] {
    color: ${codeBlockColor.main};
    font-family: 'Roboto Mono', Consolas, monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;

    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;

    ::selection {
      background: ${theme.color.neutral.mid};
    }
  }

  /* Code blocks */
  pre[class*='language-'] {
    background: ${codeBlockColor.background};
    border-radius: ${theme.space(1)};
    padding: ${theme.space(2, 4)};
    margin: 0.5em 0;
    overflow: auto;
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    border-radius: ${theme.space(0.25)};
    background-color: ${theme.color.highlight.lighter};
    padding: ${theme.space(0.125, 0.5)};
    color: ${theme.color.neutral.darkest};
    font-size: calc(${theme.fontSize[3]} * 0.875);
    word-wrap: break-word;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap;
  }

  .token.comment {
    color: ${codeBlockColor.comment};
  }

  .token.punctuation {
    color: ${codeBlockColor.punctuation};
  }

  .token.number {
    color: ${codeBlockColor.number};
  }

  .token.string,
  .token.char {
    color: ${codeBlockColor.string};
  }

  .token.operator {
    color: ${codeBlockColor.operator};
  }

  .token.keyword {
    color: ${codeBlockColor.keyword};
  }

  .token.class-name {
    color: ${codeBlockColor.class};
  }

  .token.function {
    color: ${codeBlockColor.function};
  }

  .token {
    ::selection {
      background: ${theme.color.neutral.mid};
    }
  }
`
