import { css } from '@emotion/core'

export default css`
  html {
    font-size: 1rem;
    line-height: 1.7;
    font-family: Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`
