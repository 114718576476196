export const color = {
  primary: {
    darkest: '#5A1828',
    darker: '#87243B',
    dark: '#CA3659',
    mid: '#F6416C',
    light: '#F76386',
    lighter: '#FA97AE',
    lightest: '#FCCBD6',
  },
  neutral: {
    darkest: '#1F2227',
    darker: '#2A2E35',
    dark: '#777A7E',
    mid: '#B1B3B5',
    light: '#D8D9DA',
    lighter: '#EBECEC',
    lightest: '#FFFFFFFF',
  },
  highlight: {
    darkest: '#726B28',
    darker: '#A39839',
    dark: '#D1C549',
    mid: '#FFF553',
    light: '#FFF98C',
    lighter: '#FFFBBC',
    lightest: '#FFFCD8',
  },
}
