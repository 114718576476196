import React, { createContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const PageContext = createContext({})

export const PageProvider = ({ children }) => {
  const [pages, setPages] = useState([])

  const {
    allContentfulPage: { nodes },
  } = useStaticQuery(
    graphql`
      query AllPages {
        allContentfulPage {
          nodes {
            id
            slug
            order
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    if (nodes && nodes.length && !pages.length) {
      setPages(
        nodes
          .sort((a, b) => a.order - b.order)
          .map(n => ({
            id: n.id,
            slug: n.slug,
            order: n.order,
            title: n.title,
            html: n.body.childMarkdownRemark.html,
          }))
      )
    }
  }, [nodes, pages, setPages])

  const value = {
    pages,
  }

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
