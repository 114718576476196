export const fontSize = [
  '0.422rem',
  '0.563rem',
  '0.75rem',
  '1rem',
  '1.333rem',
  '1.777rem',
  '2.369rem',
  '3.157rem',
  '4.209rem',
]

export const lineHeight = 1.7
