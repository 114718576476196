import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'
import { theme } from './theme'
import cssReset from './cssReset'
import baseStyle from './baseStyle'
import codeStyle from './codeStyle'

export const ThemeProvider = ({ children }) => {
  return (
    <EmotionThemeProvider theme={theme}>
      <Global styles={cssReset} />
      <Global styles={baseStyle} />
      <Global styles={codeStyle} />
      {children}
    </EmotionThemeProvider>
  )
}
