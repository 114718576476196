import { DOCS_TYPE } from '../context/docs-context'

const h2TagAndContentRegex = /<h2>.*<\/h2>/gi
const h2TagOnlyRegex = /<h2>|<\/h2>/gi
const cleanUriAnchorRegex = /[^\!\$&'\(\)\*\+\-\/._~:@,;=a-zA-Z0-9]/gi
const imagePOpeningTagRegex = /(<p><img)/gi
const imagePClosingTagRegex = /(<\/p>)/gi
const imagePTagRegex = /(<p><img.*)(<\/p>)/gi
const tableOfContentsPlaceholderRegex = /<div id="table-of-contents" \/>/

const addTargetBlankToExternalLinks = html => {
  return html.replace(/<a href="http/gi, '<a target="_blank" href="http')
}

const replaceImagePTags = html => {
  const tags = html.match(imagePTagRegex)

  if (!tags || tags.length) {
    return html
  }

  const modifiedTags = tags.map(m =>
    m.replace(imagePOpeningTagRegex, '<figure><img').replace(imagePClosingTagRegex, '</figure>')
  )

  tags.forEach((t, i) => {
    html = html.replace(t, modifiedTags[i])
  })

  return html
}

export const processPageHtml = html => {
  return addTargetBlankToExternalLinks(replaceImagePTags(html))
}

export const createAnchorIdFromHeader = h => {
  return h.toLowerCase().replace(cleanUriAnchorRegex, '_')
}

export const getH2HeadersContentFromHtml = html => {
  const matches = html.match(h2TagAndContentRegex)
  return matches && matches.length ? matches.map(m => m.replace(h2TagOnlyRegex, '')) : []
}

export const processDocsPageHtml = (html, type) => {
  const h2Elements = html.match(h2TagAndContentRegex)

  if (!h2Elements || !h2Elements.length) {
    // Remove table of contents placeholder if no H2 elements
    return processPageHtml(html.replace(tableOfContentsPlaceholderRegex, ''))
  }

  const h2Content = h2Elements.map(h => h.replace(h2TagOnlyRegex, ''))
  const anchorIds = h2Content.map(h => createAnchorIdFromHeader(h))

  // Create anchors
  h2Elements.forEach((el, i) => {
    html = html.replace(
      el,
      `<a style="position: relative; top: -33vh; border: 0; padding: 0; margin: 0;" id="${anchorIds[i]}"></a>${el}`
    )
  })

  // Create table of contents
  html = html.replace(
    tableOfContentsPlaceholderRegex,
    `<h3>${type === DOCS_TYPE.API_REFERENCE ? 'Public Members' : 'Table of Contents'}</h3><ul>${h2Content
      .map((c, i) => `<li><a href="#${anchorIds[i]}">${c}</a></li>`)
      .join('')}</ul>`
  )

  return processPageHtml(html)
}
