import { css } from '@emotion/core'

export default css`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font: inherit;
    vertical-align: baseline;
  }
  strong,
  b {
    font-weight: bold;
  }
  body {
    margin: 0;
  }
  ol,
  ul {
    list-style: none;
  }
  li {
    display: inline-block;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`
