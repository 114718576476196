import { DocsProvider } from './src/context/docs-context'
import { PageProvider } from './src/context/page-context'
import { ThemeProvider } from './src/styles/theme-provider'

const _importFont = fontUrl => {
  const link = document.createElement('link')
  link.setAttribute('rel', 'stylesheet')
  link.setAttribute('href', fontUrl)
  document.head.appendChild(link)
}

export const onClientEntry = () => {
  _importFont('https://fonts.googleapis.com/css?family=Roboto:400,600,700')
  _importFont('https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,600,700')
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <PageProvider>
      <DocsProvider>{element}</DocsProvider>
    </PageProvider>
  </ThemeProvider>
)
